'use client'

import Link from "next/link"
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from "@/components/ui/collapsible"
import { ChevronDown, Facebook, Instagram } from "lucide-react"
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { useTranslations } from 'next-intl';
import { useRTLAwareStyle } from "@/util/rtl";

type FooterLink = {
  text: string;
  href: string;
}

type FooterSection = {
  title: string;
  links: FooterLink[];
}

export default function Footer() {
  const t = useTranslations('common');
  const rtlAwareStyle = useRTLAwareStyle('space-x-4', 'space-x-reverse space-x-4');
  const letterSpacing = useRTLAwareStyle('tracking-wider', '')

  const footerSections: FooterSection[] = [
    {
      title: t('nav.bouquets'),
      links: [
        { text: t('nav.charmingBlooms'), href: '/charming-blooms' },
        { text: t('nav.charmingBouquets'), href: '/charming-bouquets' },
        { text: t('nav.redRomance'), href: '/red-love' },
      ]
    },
    {
      title: t('nav.arrangements'),
      links: [
        { text: t('nav.boxedBeauties'), href: '/boxed-beauties' },
        { text: t('nav.sweetheartBox'), href: '/boxed-beauties' },
        { text: t('nav.acrylicFlorals'), href: '/acrylic-florals' },
        { text: t('nav.bloomingVases'), href: '/blooming-vases' },
      ]
    },
    {
      title: t('footer.sections.resources.title'),
      links: [
        { text: t('footer.sections.resources.links.faq'), href: '/' },
        { text: t('footer.sections.resources.links.termsConditions'), href: '/terms-and-conditions' },
        { text: t('footer.sections.resources.links.privacyPolicy'), href: '/' },
      ]
    }
  ];

  const socialIcons = [
    { Icon: Instagram, href: "https://instagram.com/luvi.flowers", name: t('footer.socialMedia.instagram') },
    { Icon: Facebook, href: "https://www.facebook.com/Luvi.Flowers.dxb", name: t('footer.socialMedia.facebook') }
  ];

  return (
    <footer className="w-full border-t py-12 bg-gray-50">
      <div className="container px-4 md:px-6">
        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {footerSections.map((section, index) => (
            <div key={index} className="space-y-4">
              <h3 className={`text-lg font-semibold text-gray-900 hidden sm:block ${letterSpacing}`}>{section.title}</h3>
              <div className="hidden sm:grid gap-2">
                {section.links.map((link, linkIndex) => (
                  <Link 
                    key={linkIndex} 
                    href={link.href} 
                    className="text-gray-600 hover:text-[#bcb398] transition-colors duration-200 font-roboto" 
                    prefetch={false}
                  >
                    {link.text}
                  </Link>
                ))}
              </div>
              <Collapsible className="sm:hidden">
                <CollapsibleTrigger className={`flex w-full items-center justify-between text-base text-gray-900 font-semibold ${letterSpacing}`}>
                  {section.title}
                  <ChevronDown className="h-5 w-5 text-gray-500 transition-transform duration-200" />
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <div className="grid gap-2 mt-2">
                    {section.links.map((link, linkIndex) => (
                      <Link 
                        key={linkIndex} 
                        href={link.href} 
                        className="text-gray-600 hover:text-[#bcb398] transition-colors duration-200 font-roboto" 
                        prefetch={false}
                      >
                        {link.text}
                      </Link>
                    ))}
                  </div>
                </CollapsibleContent>
              </Collapsible>
            </div>
          ))}
          <div className="flex flex-col space-y-4 items-center">
            <h3 className={`text-base text-gray-900 font-semibold ${letterSpacing}`}>{t('footer.followUs')}</h3>
            <div className={`flex ${rtlAwareStyle}`}>
              {socialIcons.map(({ Icon, href, name }, index) => (
                <Link 
                  key={index} 
                  href={href} 
                  className="text-gray-600 hover:text-[#bcb398] transition-colors duration-200" 
                  prefetch={false}
                  aria-label={`${name} (opens in a new tab)`}
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  <Icon className="h-6 w-6" />
                  <VisuallyHidden.Root>{name}</VisuallyHidden.Root>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-12 pt-8 border-t border-gray-200">
          <div className="flex flex-col md:flex-row justify-center items-center">
            <p className="text-sm text-gray-500 mb-4 md:mb-0">{t('footer.copyright')}</p>
          </div>
        </div>
      </div>
    </footer>
  )
}