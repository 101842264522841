'use client'

import { useTranslations } from 'next-intl'
import Hero from '@/components/home/Hero'
import InfoCard from '@/components/shared/cards/InfoCard'
import AnimatedContent, { AnimatedSection } from '@/components/shared/AnimatedContent'
import Image from 'next/image'

// Import your images here
import charmingBloomsImage from '@/public/images/bloom.jpg'
import charmingBouquetsImage from '@/public/images/charming-bouquet.jpg'
import redRomanceImage from '@/public/images/red-romance.jpg'
import hampersImage from '@/public/images/hamper-outside.jpg'
import boxedBeautiesImage from '@/public/images/boxed-beauties.jpg'
import sweetheartBoxImage from '@/public/images/sweetheart-box.jpg'
import acrylicFloralsImage from '@/public/images/acrylic-floral.jpg'
import bloomingVasesImage from '@/public/images/blooming-vases.jpg'
import aboutUsImage from '@/public/images/about-us.jpg'
import HeroBanner from '@/public/images/valentine.jpg'
import { useRTLAwareStyle } from '@/util/rtl'

export default function Home({
  params: { lang }
}: {
  params: { lang: string }
}) {
  const t = useTranslations('home')
  const letterSpacing = useRTLAwareStyle('tracking-wider', '')

  const categories = [
    { key: 'charmingBlooms', image: charmingBloomsImage, href: '/charming-blooms' },
    { key: 'charmingBouquets', image: charmingBouquetsImage, href: '/charming-bouquets' },
    { key: 'hampersAndBundles', image: hampersImage, href: '/hampers-and-bundles' },
    { key: 'redRomance', image: redRomanceImage, href: '/red-love' },
    { key: 'boxedBeauties', image: boxedBeautiesImage, href: '/boxed-beauties' },
    { key: 'sweetheartBox', image: sweetheartBoxImage, href: '/boxed-beauties' },
    { key: 'acrylicFlorals', image: acrylicFloralsImage, href: '/acrylic-florals' },
    { key: 'bloomingVases', image: bloomingVasesImage, href: '/blooming-vases' },
  ]

  return (
    <main className='w-full'>
      <AnimatedContent>
      <Hero 
        title1={t('hero.title1')} 
        title2={t('hero.title2')} 
        slogan={t('hero.slogan')}
        lang={lang} 
        imageSrc={HeroBanner} 
      />
        
        <AnimatedSection>
          <div className='w-full bg-gray-50 py-16'>
            <div className='w-full md:container md:mx-auto md:px-4'>
              <h2 className={`${letterSpacing} text-2xl md:text-3xl font-semibold text-center mb-12 text-gray-800`}>{t('ourCollections')}</h2>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
                {categories.map((category) => (
                  <InfoCard
                    key={category.key}
                    title={t(`${category.key}.title`)}
                    desc={t(`${category.key}.description`)}
                    href={category.href}
                    alt={t(`${category.key}.alt`)}
                    img={category.image}
                  />
                ))}
              </div>
            </div>
          </div>
        </AnimatedSection>

        <AnimatedSection>
          <div className='w-full bg-white py:4 md:py-16'>
            <div className='w-full md:container md:mx-auto md:px-4 text-center'>
              <h2 className='text-3xl md:text-4xl font-semibold mb-6 text-gray-800'>{t('aboutUs.title')}</h2>
              <p className='text-lg text-gray-600 max-w-2xl mx-auto mb-12'>{t('aboutUs.description')}</p>
              {/* <Image
                src={aboutUsImage}
                alt={t('aboutUs.imageAlt')}
                width={800}
                height={400}
                className="shadow-lg mx-auto"
              /> */}

              <div className='flex items-center justify-center'>
              <div className="relative w-[700px] h-[400px] md:h-[600px] overflow-hidden">
                    <Image
                         src={aboutUsImage}
                         alt={t('aboutUs.imageAlt')}
                        fill
                        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw"
                        className=" object-cover transition-transform duration-300 hover:scale-105"
                    />
              </div>
              </div>
            </div>
          </div>
        </AnimatedSection>
      </AnimatedContent>
    </main>
  )
}